<template>
	<!-- 销售汇总 -->
	<div class="salesSummary">
		<!-- 导出模板 -->
		<div id="export" style="position: fixed;top: -999999px;">
			<div class="export-template">
				<div id="template-title">
					<div style="line-height: 70px;font-size: 22px;" align=center>
						<strong>
							<font>{{ supplier_info.supplier_name }}未配订单汇总</font>
						</strong>
					</div>
					<table border=0 cellSpacing=0 cellPadding=0 width="100%" style="line-height: 20px;font-size: 14px;">
						<tbody>
							<tr>
								<td>
									<font>打印时间：<span>{{ currentDateTime }}</span></font>
								</td>

							</tr>
							<tr>
								<td>
									<font>下单时间：<span>{{ searchForm.begin + '至' + searchForm.end }}</span></font>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div id="template-table">
					<table border=1 cellSpacing=0 cellPadding=1 width="100%"
						style="border-collapse:collapse;bordercolor:#333333;font-size: 14px;">
						<thead>
							<tr>
								<td width="3%">
									<div align=center><b>序号</b></div>
								</td>
								<td width="8%">
									<div align=center><b>应配日期</b></div>
								</td>
								<td width="25%">
									<div align=center><b>商品名称</b></div>
								</td>
								<td width="10%">
									<div align=center><b>商品卖点</b></div>
								</td>
								<td width="8%">
									<div align=center><b>自定义属性</b></div>
								</td>
								<td width="8%">
									<div align=center><b>订单数量</b></div>
								</td>
								<td width="10%">
									<div align=center><b>订单金额</b></div>
								</td>
								<td width="10%">
									<div align=center><b>成本金额</b></div>
								</td>
								<td width="8%">
									<div align=center><b>毛利</b></div>
								</td>
								<td width="10%">
									<div align=center><b>毛利率</b></div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in tableData" :key="index">
								<td align=center>{{ index + 1 }}</td>
								<td align=center>{{ item.date }}</td>
								<td align=center>{{ item.goods_name }}</td>
								<td align=center>{{ item.goods_intro }}</td>
								<td align=center>{{ item.attribute }}</td>
								<td align=center>{{ item.count }}</td>
								<td align=center>{{ item.amount }}</td>
								<td align=center>{{ item.cost_amount }}</td>
								<td align=center>{{ item.profit }}</td>
								<td align=center>{{ item.profit_rate }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">下单时间:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="datetimerange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
							:default-time="['00:00:00', '23:59:59']" style="width: 100%;">
						</el-date-picker>
						<!-- <el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
                        </el-date-picker> -->
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">应配日期:</div>
					<div class="item-input">
						<el-date-picker v-model="searchForm.date" type="date" value-format="yyyy-MM-dd"
							style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配送仓库:</div>
					<div class="item-input">
						<el-select v-model="searchForm.warehouse_id" placeholder="请选择">
							<el-option v-for="item in warehouseOptions" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">售后订单:</div>
					<div class="item-input">
						<el-select v-model="searchForm.has_service" placeholder="请选择">
							<el-option v-for="item in hasServiceList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>

					</div>
				</div>
				<div class="l-item">
					<div class="item-label">商品分类:</div>
					<div class="item-input">
						<el-select v-model="selectCate" multiple collapse-tags style="margin-left: 20px;"
							placeholder="请选择">
							<el-option v-for="item in cateList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">包含商品:</div>
					<div class="item-input">
						<el-input v-model="searchForm.key" placeholder="请输入商品名称" />
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning" @click.stop="handleSearch">搜索</el-button>
					<el-button
						@click.stop="pageNum = 10, selectCate = [], searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], handleSearch()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="success" @click.stop="handlePrint">打印</el-button>
				<el-button type="warning" @click.stop="handleExport">导出</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>

			<el-table :data="tableData" style="width: 100%" :header-cell-style="{
                background: '#f5f7fa',
                fontWeight: 'bold',
                color: '#303133'
            }" v-loading="loading" show-summary>
				<el-table-column type="index" label="序号" align="center"></el-table-column>
				<el-table-column prop="date" label="应配日期" align="center"></el-table-column>
				<el-table-column prop="category_title" label="商品分类" align="center"></el-table-column>
				<el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
				<el-table-column prop="goods_intro" label="商品卖点" align="center"></el-table-column>
				<el-table-column prop="attribute" label="自定义属性" align="center"></el-table-column>
				<el-table-column prop="count" label="订单数量" align="center"></el-table-column>
				<el-table-column prop="amount" label="订单金额" align="center"></el-table-column>
				<el-table-column prop="cost" label="成本价" align="center"></el-table-column>
				<el-table-column prop="cost_amount" label="成本金额" align="center"></el-table-column>
				<el-table-column prop="profit" label="毛利" align="center"></el-table-column>
				<el-table-column prop="profit_rate" label="毛利率" align="center"></el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import {
		getLodop
	} from '@/utils/CLodopfuncs.js'
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
					has_service: 0,
					cate_ids: ""
				},
				// 仓库
				warehouseOptions: [],
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				// 售后
				hasServiceList: [{
						id: 0,
						name: '不含售后'
					},
					{
						id: 1,
						name: '仅含售后'
					}
				],
				// 供应商信息
				supplier_info: {},
				currentDateTime: this.getCurrentDateTime(),
				cateList: [],
				selectCate: []
			}
		},
		mounted() {
			// 仓库
			this.getWarehouseList()
			// 时间
			this.dateVal = [this.getCurrentDate() + ' ' + '00:00:00', this.getCurrentDate() + ' ' + '23:59:59']
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			// 列表
			this.getList()
			this.supplier_info = JSON.parse(sessionStorage.getItem('supplier_info'))
			this.getCateList()
		},
		methods: {
			handleSearch() {
				this.page = 1
				this.searchForm.begin = this.dateVal[0]
				this.searchForm.end = this.dateVal[1]
				if (this.selectCate) {
					this.searchForm.cate_ids = this.selectCate.join(",")
				}
				this.getList()
			},
			getCateList() {
				this.$http.post(`erp/v1/goods_category/all`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.cateList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			getList() {
				this.loading = true;
				this.$http.post(`/erp/v1/order/summary_no_delivery`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data
						this.total = this.tableData.length
					} else {
						this.$message.error(msg);
					}
				});
			},
			getCurrentDateTime() {
				const now = new Date();
				const year = now.getFullYear();
				const month = this.padNumber(now.getMonth() + 1); // 月份是从0开始的
				const day = this.padNumber(now.getDate());
				const hours = this.padNumber(now.getHours());
				const minutes = this.padNumber(now.getMinutes());
				const seconds = this.padNumber(now.getSeconds());
				return (
					year +
					'-' +
					month +
					'-' +
					day +
					' ' +
					hours +
					':' +
					minutes +
					':' +
					seconds
				);
			},
			padNumber(num) {
				return num < 10 ? '0' + num : num;
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 仓库
			getWarehouseList() {
				this.$http.post(`erp/v1/warehouse/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.warehouseOptions = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 打印
			handlePrint() {
				setTimeout(() => {
					// var LODOP = getLodop();
					// // var titleStyle = "<style> .title-box{display: flex;justify-content: space-between} .title-box .l-item {line-height: 30px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
					// LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, document.getElementById("export").innerHTML);
					// LODOP.PREVIEW()
					// this.printingList = []

					var LODOP = getLodop();
					var titleStyle =
						"<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .box-l{display: flex;justify-content: space-between} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
					LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, titleStyle + document.getElementById("export")
						.innerHTML);
					LODOP.PREVIEW()
					LODOP.NewPageA();
				}, 500);
			},
			// 导出
			handleExport() {
				this.$http.post(`/erp/v1/order/summary_no_delivery`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm,
					export: 1
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url, '_self')
					} else {
						this.$message.error(msg);
					}
				});
			},
		},
	}
</script>

<style lang="scss" scoped></style>